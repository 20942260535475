import React from "react"
import classnames from "classnames"
import {
  connect,
  Field,
  FieldProps,
  FormikContextType,
  ErrorMessage,
} from "formik"

export interface FormProps {
  name: string
  label: string
  type?: string
  component?:
    | string
    /* eslint @typescript-eslint/no-explicit-any: "off" */
    | React.ComponentType<FieldProps<any>>
    | React.ComponentType<void>
  className?: string
  rows?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  disabled?: boolean
  "data-testid"?: string
  pattern?: string
  noValidate?: boolean
}

const FormItemImpl = ({
  name,
  label,
  type,
  component,
  className,
  rows,
  onChange,
  children,
  disabled,
  pattern,
  noValidate,
  formik,
  "data-testid": dataTestId,
}: FormProps & { formik?: FormikContextType<any> }) => {
  const value = name
    .split(".")
    .reduce((o, i) => o && o[i], formik ? formik.values : {})
  return (
    <div className={classnames("mb-4 mt-4 px-2 relative", className)}>
      <Field
        id={name}
        name={name}
        {...(type && { type })}
        {...(rows && { rows })}
        {...(component && { component })}
        {...(onChange && { onChange })}
        {...(disabled && { disabled })}
        {...(pattern && { pattern })}
        {...(noValidate && { noValidate })}
        {...(dataTestId && { "data-testid": dataTestId })}
        className={classnames(
          value ? "" : "empty",
          "form-input",
          "w-full",
          "text-bm-blue-700",
          "border-b",
          disabled ? "border-gray-300" : "border-gray-500",
          disabled ? "bg-gray-300" : "bg-gray-100",
          "appearance-none",
          "rounded-none",
          "leading-tight",
          "focus:outline-none",
          "focus:border-gray-800",
          "border-box"
        )}
      >
        {children}
      </Field>
      <label
        htmlFor={name}
        className={classnames(
          "form-label",
          "block",
          disabled ? "text-bm-blue-200" : "text-bm-blue-500",
          "font-bold"
        )}
      >
        {label}
      </label>
      <ErrorMessage name={name} component="div" className="text-bm-brown" />
    </div>
  )
}

export const FormItem = connect(FormItemImpl)
