import { useState } from "react"

const { GATSBY_STRIPE_PUBLIC_KEY } = process.env

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Stripe: any
  }
}

const useAsyncStripe = () => {
  const [stripe, setStripe] = useState(null)

  if (typeof window == "undefined") return stripe
  if (stripe) return stripe

  const key = GATSBY_STRIPE_PUBLIC_KEY || ""

  if (window.Stripe) {
    setStripe(window.Stripe(key))
  } else {
    const el = document.querySelector("#stripe-js")
    el &&
      el.addEventListener("load", () => {
        setStripe(window.Stripe(key))
      })
  }

  return stripe
}

export default useAsyncStripe
