import { connect, FormikContextType } from "formik"
import { ReactElement, useEffect, useRef } from "react"

interface OuterProps<Values> {
  onChange: (
    currState: FormikContextType<Values>,
    prevState: FormikContextType<Values> | null
  ) => void
}

interface Props<Values> extends OuterProps<Values> {
  formik: FormikContextType<Values>
}

const Effect = <Values,>({ formik, onChange }: Props<Values>) => {
  const ref = useRef<FormikContextType<Values> | null>(null)
  useEffect(() => {
    onChange(formik, ref.current)
    ref.current = formik
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik])

  return null
}

export default connect(Effect) as <Values>(
  props: OuterProps<Values>
) => ReactElement
