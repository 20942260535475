import React, { Fragment } from "react"
import SSLFooter from "../components/ssl_footer"
import states from "../lib/states"
import { FormItem } from "../components/form"
import { FieldProps, Formik, Form } from "formik"
import { Link, navigate } from "gatsby"
import {
  injectStripe,
  CardElement,
  Elements,
  StripeProvider,
  ReactStripeElements,
} from "react-stripe-elements"
import {
  shippingSchema,
  ShippingSchema,
  AddressSchema,
} from "../../lib/schemas/shipping"
import OrderSummary from "../components/checkout/order-summary"
import useCheckout from "../hooks/use-checkout"
import SpinOrText from "../components/spin_or_text"
import useError from "../hooks/use-error"
import useAsyncStripe from "../hooks/use-stripe"
import Effect from "../components/formik-effect"
import idx from "idx"

const handleSubmit =
  (
    setError: (message: string) => void,
    clearError: () => void,
    addAddresses: (addresses: ShippingSchema) => void,
    stripe: ReactStripeElements.StripeProps
  ) =>
  async (
    values: ShippingSchema,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    clearError()
    const form = shippingSchema.cast(values)

    addAddresses(form)

    const firstName = form.billingSameAsShipping
      ? form.shippingAddress.firstName
      : form.billingAddress && "firstName" in form.billingAddress
      ? form.billingAddress.firstName
      : ""
    const lastName = form.billingSameAsShipping
      ? form.shippingAddress.lastName
      : form.billingAddress && "lastName" in form.billingAddress
      ? form.billingAddress.lastName
      : ""

    const { token } = await stripe.createToken({
      name: [firstName, lastName].join(" "),
    })

    setSubmitting(false)

    if (!token) {
      setError(
        "There is a problem processing your order at this time. Please try again later or contact us directly."
      )
      throw "Token Missing"
    }

    navigate("/confirmation", {
      state: { token: token.id },
    })
  }

const CheckboxComponent = ({
  field,
  form,
  "data-testid": dataTestId,
}: FieldProps & { "data-testid"?: string }) => {
  return (
    <input
      {...field}
      {...(dataTestId && { "data-testid": dataTestId })}
      type="checkbox"
      checked={form.values[field.name] ? true : false}
      className="relative z-10"
    />
  )
}

export const CheckoutBody = ({
  stripe,
}: {
  stripe?: ReactStripeElements.StripeProps
}) => {
  const checkoutState = useCheckout()
  const { addresses, addAddresses, setZip, setState } = checkoutState
  const { error, message, setError, clearError } = useError()

  const defaultValues = {
    shippingAddress: AddressSchema("Shipping", true).default(),
    billingAddress: AddressSchema("Billing", false).default(),
    billingSameAsShipping: true,
    email: "",
  }

  if (!stripe) return <div>Payment Processing is Temporarily Unavailable</div>

  return (
    <div className="flex flex-wrap">
      {error && <div className="w-full">{message}</div>}
      <div className="w-full md:w-1/2">
        <h2>Checkout</h2>
        <Formik
          validationSchema={shippingSchema}
          initialValues={addresses}
          onSubmit={handleSubmit(setError, clearError, addAddresses, stripe)}
        >
          {({ resetForm, isSubmitting, values }) => (
            <Form>
              <Effect<ShippingSchema>
                onChange={(currState, prevState) => {
                  const cZip = idx(
                    currState,
                    (_) => _.values.shippingAddress.zip
                  )
                  const pZip = idx(
                    prevState,
                    (_) => _.values.shippingAddress.zip
                  )
                  const cState = idx(
                    currState,
                    (_) => _.values.shippingAddress.state
                  )
                  const pState = idx(
                    prevState,
                    (_) => _.values.shippingAddress.state
                  )
                  if (cZip && cZip != pZip) {
                    setZip(cZip)
                  }
                  if (cState && cState != pState) {
                    setState(cState)
                  }
                }}
              />
              <div>
                <fieldset className="mx-auto border-box">
                  <legend className="font-fancy text-2xl text-bm-brown">
                    Shipping Address
                  </legend>
                  <div className="flex flex-wrap flex-row">
                    <FormItem
                      name="shippingAddress.firstName"
                      label="First Name"
                      className="w-full md:w-1/3"
                    />
                    <FormItem
                      name="shippingAddress.middleInitial"
                      label="M.I."
                      className="w-full md:w-1/3"
                    />
                    <FormItem
                      name="shippingAddress.lastName"
                      label="Last Name"
                      className="w-full md:w-1/3"
                    />
                    <FormItem
                      name="shippingAddress.address1"
                      label="Address Line 1"
                      className="w-full"
                    />
                    <FormItem
                      name="shippingAddress.address2"
                      label="Address Line 2"
                      className="w-full"
                    />
                    <FormItem
                      name="shippingAddress.city"
                      label="City"
                      className="w-full md:w-1/3"
                    />
                    <FormItem
                      component="select"
                      name="shippingAddress.state"
                      label="State"
                      className="w-full md:w-1/3"
                    >
                      <option
                        key={1}
                        label="Please Select Your State"
                        value=""
                      />
                      {Object.entries(states).map(([abbrev, name], i) => (
                        <option key={i + 1} label={name} value={abbrev}>
                          {name}
                        </option>
                      ))}
                    </FormItem>
                    <FormItem
                      name="shippingAddress.zip"
                      label="Zip"
                      type="text"
                      pattern="[-0-9]*"
                      noValidate
                      className="w-full md:w-1/3"
                    />
                    <FormItem
                      name="shippingAddress.phone"
                      label="Phone Number"
                      type="tel"
                      className="w-full"
                    />
                    <FormItem name="email" label="E-Mail" className="w-full" />
                  </div>
                </fieldset>
              </div>
              <fieldset className="mx-auto border-box">
                <legend className="font-fancy text-2xl text-bm-brown">
                  Billing Address
                </legend>
                <div className="flex flex-wrap flex-row">
                  <FormItem
                    data-testid="billingSameAsShipping"
                    name="billingSameAsShipping"
                    label="Same as shipping address"
                    className="form-checkbox w-full"
                    type="checkbox"
                    component={CheckboxComponent}
                  />
                  {!values.billingSameAsShipping && (
                    <>
                      <FormItem
                        name="billingAddress.firstName"
                        label="First Name"
                        className="w-full md:w-1/3"
                      />
                      <FormItem
                        name="billingAddress.middleInitial"
                        label="M.I."
                        className="w-full md:w-1/3"
                      />
                      <FormItem
                        name="billingAddress.lastName"
                        label="Last Name"
                        className="w-full md:w-1/3"
                      />
                      <FormItem
                        name="billingAddress.address1"
                        label="Address Line 1"
                        className="w-full"
                      />
                      <FormItem
                        name="billingAddress.address2"
                        label="Address Line 2"
                        className="w-full"
                      />
                      <FormItem
                        name="billingAddress.city"
                        label="City"
                        className="w-full md:w-1/3"
                      />
                      <FormItem
                        component="select"
                        name="billingAddress.state"
                        label="State"
                        className="w-full md:w-1/3"
                      >
                        <option
                          key={1}
                          label="Please Select Your State"
                          value=""
                        />
                        {Object.entries(states).map(([abbrev, name], i) => (
                          <option key={i + 1} label={name} value={abbrev}>
                            {name}
                          </option>
                        ))}
                      </FormItem>
                      <FormItem
                        name="billingAddress.zip"
                        label="Zip"
                        type="text"
                        pattern="[-0-9]*"
                        noValidate
                        className="w-full md:w-1/3"
                      />
                      <FormItem
                        name="billingAddress.phone"
                        label="Phone Number"
                        type="tel"
                        className="w-full"
                      />
                    </>
                  )}
                </div>
              </fieldset>
              <div>
                <div>
                  <h3 className="font-fancy text-2xl text-bm-brown">Payment</h3>
                  <div className="w-full mb-4 mt-4 px-2 relative">
                    <CardElement
                      classes={{
                        base: "StripeElement border-b border-gray-500",
                      }}
                      style={{
                        base: {
                          color: "black",
                          "::placeholder": {
                            fontSize: "16px",
                            fontFamily: "serif",
                            fontWeight: "700",
                            color: "#a0aec0",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-wrap">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-bm-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-1 md:mr-2 my-1"
                  >
                    <SpinOrText text="Continue" spin={isSubmitting} />
                  </button>
                  <button
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => resetForm({ values: defaultValues })}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-1 md:mr-2 my-1"
                  >
                    Clear
                  </button>
                  <Link
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded inline-block my-1"
                    to={isSubmitting ? "/checkout" : "/basket"}
                  >
                    Back to Basket
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <OrderSummary className="w-full md:w-1/2" checkoutState={checkoutState} />
      <SSLFooter />
    </div>
  )
}

const InjectedCheckoutBody = injectStripe(CheckoutBody)

const Checkout = () => {
  const stripe = useAsyncStripe()

  if (typeof window == `undefined`)
    return <Fragment>Checkout Temporarily Unavailable</Fragment>

  if (!stripe) return <Fragment>Loading Checkout</Fragment>

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <InjectedCheckoutBody />
      </Elements>
    </StripeProvider>
  )
}

export default Checkout
